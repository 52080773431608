$header-bg-color: black;
$header-text-color: #FFFFFF;
$header-text-hover-color: #f0e15d;
$header-text-active-color: #f0e15d;
$header-burger-color: #FFFFFF;

$footer-bg-color: black;
$footer-text-color: #FFFFFF;
$footer-text-hover-color: #BBBBBB;
$footer-text-active-color: #FE4A49;

$socials-color: #FFFFFF;
$links-color: #FFFFFF;

$theme-light-bg-color: #BBBBBB;
$theme-light-text-color: #191919;
$theme-dark-bg-color: black;
$theme-dark-text-color: #dfdfdf;

$slider-icon-color-primary: #AAA333;
$slider-icon-color-secondary: #dfdfdf;

$module-spacing-breakpoint: 768px;
$module-spacing-mobile: 15px;
$module-spacing-desktop: 30px;

/* Customized heights of product images*/

.img-lTOxWq5N2eTXQlcVWpl20 {
    height: 260px;
    @media(min-width: 768px) {
        height: 360px;
    }
    
}

.img-6Zb6ujTvG9wXs2nbhP0CWf {
    height:200px;
    @media(min-width: 768px) {
        height: 220px;
    }
}