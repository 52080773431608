html, body, #root {
    width: 100%;
    height: 100%;
}

iframe {
    display: block;
    max-width: 100%;
}

#root {
    display: flex; 
    flex-direction: column; 
}

body {
    margin: 0;
    font-family: 'Roboto Slab', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus,
button:focus {
    outline: none;
}

.c-module {
    &-light {
        background-color: $theme-light-bg-color;
        color: $theme-light-text-color;
    }
    
    &-dark {
        background-color: $theme-dark-bg-color;
        color: $theme-dark-text-color;
    }

    // &:last-of-type {
    //     padding-bottom: $module-spacing-mobile;
    
    //     @media (min-width: $module-spacing-breakpoint) {
    //         padding-bottom: $module-spacing-desktop;
    //     }
    // }

    // &:not(.c-module-light) + .c-module-light,
    // &:not(.c-module-dark) + .c-module-dark {
    //     &:last-of-type {
    //         padding-bottom: 0;
    //     }
    // }
    
    .no-spacing {
        margin-top: 0;
        margin-bottom: 0;
    }
}