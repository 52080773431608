@import '../../styles/mixins';

.c-shop{
    margin-top: $module-spacing-mobile;
    margin-bottom: $module-spacing-mobile;

    @media (min-width: $module-spacing-breakpoint) {
        margin-top: $module-spacing-desktop;
        margin-bottom: $module-spacing-desktop;
    }
}

