@import '../../styles/mixins';

.c-button {
    .c-module-light,
    .c-module-dark {
        background: none !important;
        color: transparent !important;
    }
    /* Overwrite .c-module styling */
    display: flex;
    justify-content: center;
    width: 250px;
    margin: 0 auto;
    padding: getSpace(2) getSpace(3);
    color: rgba(255,255,255,1);
    background: none;
    border-radius: 0;
    font-size: 14px;
    font-weight: 700;
    transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    margin-bottom: 30px;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
        transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    }

    &-light {
        color: $theme-light-bg-color;
        // box-shadow: inset 0 0 0.8em rgba($theme-light-bg-color,0.3), 0 0 0.8em rgba($theme-light-bg-color,0.3);
        border: $theme-light-bg-color solid 2px;
    }

    &-light:hover,
    &-light:focus {
        color: $theme-light-text-color;
        box-shadow: inset 0 0 0 rgba($theme-light-bg-color,0.3), 0 0 1.2em rgba($theme-light-bg-color,0.5);
        background-color: $theme-light-bg-color;
    }

    &-dark {
        color: $theme-dark-bg-color;
        // box-shadow: inset 0 0 0.8em rgba($theme-dark-bg-color,0.3), 0 0 0.8em rgba($theme-dark-bg-color,0.3);
        border: $theme-dark-bg-color solid 2px;
    }

    &-dark:hover,
    &-dark:focus {
        color: $theme-dark-text-color;
        box-shadow: inset 0 0 0 rgba($theme-dark-bg-color,0.3), 0 0 1.2em rgba($theme-dark-bg-color,0.5);
        background-color: $theme-dark-bg-color;
    }
}