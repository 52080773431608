@import '../../styles/mixins';

.c-product {
    padding: 30px 0 15px 0;
    border-bottom: 1px solid white;

    &__img-container {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__image {
        margin: auto;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        @media(min-width: 768px) {
            padding-bottom: 0;
        }
    }


    &-details {
        text-align: center;
        hr {
            border: 5px;
        }
    }

    &-details > *{
        margin-bottom:20px;
     }

    &-controls {
        text-align: center;
        &__quantity-input {
            display: inline-block!important;
            padding: 8px 10px;
            font-size: 16px;
            border: 2px solid transparent;
            width: 60px;
            margin-right: 5px;
        }

        &__button {
            display: inline-block!important;
            -webkit-appearance: none;
        }
    }
}