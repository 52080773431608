@import '../../styles/mixins';

.c-cart {
    padding-top: 20px;
    
    .paypal-buttons {
        margin-top: 20px;
        z-index: 0;
    }
    table {
        width: 100%;
    }

    hr {
        background-color: white;
    }

    &-shipping-options, &-validations {
        input {
            margin-right: 10px;
        }
    }

    &-validations {
        margin-top: 30px;
        &__agbs {
            padding-bottom: 30px;
        }
    }

    

    &__remove-button {
        background:none;
        border:none;
        margin:0;
        padding:0;
        color: lightgray;
    }

    &-table{
        th {
            width: 30%;
            vertical-align: top;
        }
        td {
            vertical-align: top;
        }

    
        &--mobile {
            th {
                    vertical-align: top;
                    padding-right: 20px;
                }
        
            td {
                    vertical-align: middle;
                }
        }
    }

    &__input-quantity {
        width: 60px;
    }
}

.c-shop-feedback {
    padding-top: 30px;
    text-align: center;
}