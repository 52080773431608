$cubic-bezier-on: 240ms cubic-bezier(0.42, 0.13, 0.35, 0.98);
$cubic-bezier-off: 200ms cubic-bezier(0.42, 0.13, 0.35, 0.98);


@keyframes heartbeat {
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}  

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-hide {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    100% {
        visibility: hidden;
    }
}

@keyframes shadow-drop-2-center {
    0% {
        transform: translateZ(0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        transform: translateZ(50px);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}